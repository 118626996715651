// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, useContext, Fragment, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const PeopleShowcase = lazy(() => import("../Modules/PeopleShowcase"))
const LogoSlider = lazy(() => import("../Modules/LogoSlider"))
const PeopleCycle = lazy(() => import("../Modules/PeopleCycle"))
const DoMore = lazy(() => import("../Modules/DoMore"))
const AppTiles = lazy(() => import("../Modules/AppTiles"))
const PeopleSteps = lazy(() => import("../Modules/PeopleSteps"))
const Testimonials = lazy(() => import("../Modules/Testimonials"))
const StuckPeople = lazy(() => import("../Modules/StuckPeople"))
const Footer = lazy(() => import("../Modules/Footer"))

// ===============================================================
const ForPeople = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { people, getPeople } = appContext

	// ---------------------------------------------------------------
	// Get content and scroll to top on load
	useEffect(() => {
		getPeople()
		window.scrollTo(0, 0)
		// eslint-disable-next-line

	}, [])



	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{people ? (
					<Theme id="for-people" className="for-people">
						<AppNavbar />
						<PeopleShowcase />
						<LogoSlider />
						<PeopleCycle />
						<DoMore />
						{/* <PeopleSteps /> */}
						<AppTiles />
						<Testimonials />
						<StuckPeople />
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default ForPeople
