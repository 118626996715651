// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, useContext, Fragment, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import StuckSwitch from "../Modules/StuckSwitch"
import SwitchHeader from "../Modules/SwitchHeader"
import SwitchSteps from "../Modules/SwitchSteps"
import SwitchStepsTabs from "../Modules/SwitchStepsTabs"
import Theme from "../Modules/Theme"
import WhySwitch from "../Modules/WhySwitch"

// Lazy Modules
const SwitchShowCase = lazy(() => import("../Modules/SwitchShowCase"))
const MMASummary = lazy(() => import("../Modules/MMASummary"))
const HowMMABusiness = lazy(() => import("../Modules/HowMMABusiness"))
const BusinessTabs = lazy(() => import("../Modules/BusinessTabs"))
const BusinessSliders = lazy(() => import("../Modules/BusinessSliders"))
const BusinessSteps = lazy(() => import("../Modules/BusinessSteps"))
const Clients = lazy(() => import("../Modules/Clients"))
const StuckBusiness = lazy(() => import("../Modules/StuckBusiness"))
const Footer = lazy(() => import("../Modules/Footer"))

// ===============================================================
const SwitchToUs = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { business, getBusiness } = appContext

	// ---------------------------------------------------------------
	// Scroll to top on load and fetch content
	useEffect(() => {
		// window.scrollTo(0, 0)
		getBusiness()
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{business ? (
					<Theme id="for-business" className="for-business">
						<AppNavbar />
						<SwitchShowCase />
						<SwitchHeader />
						<SwitchStepsTabs/>
						<SwitchSteps />
						<WhySwitch/>
						<StuckSwitch />
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default SwitchToUs
