// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useContext, Fragment } from 'react'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
	AccordionItemState,
} from 'react-accessible-accordion'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

// Context
import AppContext from '../../Context/App/appContext'
import Button from '../Modules/Button'

// ===============================================================
const HowToDepositMoneyWhatsapp = ({ bgColor, reverse }) => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { people, image_url } = appContext

	// ---------------------------------------------------------------
	// Destructured variables

	const button = {
		id: '1',
		flatData: {
			isExternalLink: true,
			buttonText: 'Send now',
			buttonLink: 'https://send.moneymarketaccount.co.za/',
			buttonBgColor: [
				{
					flatData: {
						colorHex: '#ce181e',
					},
				},
			],
			buttonTextColor: null,
			buttonBorderColor: null,
		},
	}

	return (
		<Fragment>
			<div
				className='how-to-deposit-money how-to-use how-to-deposit-money-whatsapp'
				id='deposit-money'
				style={{ backgroundColor: bgColor || '#ffffff' }}
			>
				<div className='bg-top' style={{ backgroundColor: bgColor || '#ffffff' }}></div>
				<div className={`how-to-use-section ${reverse && 'reverse'} how-to-use-section-whatsapp`} style={{ marginTop: '-50px', marginBottom: '-40px', display: "block" }}>



					<div >
						<h2 style={{ textAlign: 'center', maxWidth: '100%',marginBottom : '15px' }}>Top-up your voucher account online:</h2>
						<div style={{display: 'flex',flexDirection: 'row' , flexWrap : 'wrap'}}>
						<div className='topUpItem'>
							<div className='topUpItemImg'>
								<img style={{ height: '77px' }} src='https://cms.computicket.com/api/assets/mma/76526c44-8494-4baf-a5ca-0d016ebc9ba1/ozoimage.png?version=0'></img>
							</div>
							<h6 className='topUpItemHeading'>EFT with Ozow</h6>
							<div className='topUpItemBody'>If you can’t get to a store to deposit money into your account, you can use Ozow Instant EFT via the Shoprite app or click the link below to top up your account.</div>

							<div className='topUpItemBtn'>
								<a className='btn-share-whatsapp' target="_blank" href='https://send.moneymarketaccount.co.za/' rel="noreferrer" >
									Send now
								</a>
							</div>
						</div>
						<div className='topUpItem'>
							<div className='topUpItemImg'>
								<img style={{ height: '77px' }} src='https://cms.computicket.com/api/assets/mma/22200111-050f-4028-981f-ce04fc08c235/ctk.png?version=0'></img>
							</div>
							<h6 className='topUpItemHeading'>Using a bank card on Computicket</h6>
							<div className='topUpItemBody'>Visit computicket.com and use your credit card to top up your account.</div>
							<div className='topUpItemBtn'>
								<a className='btn-share-whatsapp' target="_blank" href='https://tickets.computicket.com/event/shoprite_multiple_use_grocery_voucher/7157961/7157975/107235' rel="noreferrer" >
									Send now
								</a>

							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='bg-bot' style={{ backgroundColor: bgColor || '#ffffff' }}></div>
		</div>
		</Fragment >
	)
}

export default HowToDepositMoneyWhatsapp
