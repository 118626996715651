import { useContext, useRef, useEffect } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useNavigate } from "react-router-dom"

// Context
// import AppContext from '../../Context/App/appContext'
import RegisterContext from "../../Context/Register/registerContext"

const LoginToken = ({ heading }) => {
	// ---------------------------------------------------------------
	// Context
	// const appContext = useContext(AppContext)
	const registerContext = useContext(RegisterContext)

	// Destructured variables
	// const { image_url } = appContext

	const {
		apiProxy,
		setCurrentStep,
		setMobileNumber,
		mobileNumber,
		setRecap,
		recap,
		setErrorMobileNumber,
		errorMobileNumber,
		errorPin,
		pin,
		setPin,
		setToken,
		loginError,
		setLoginError,
	} = registerContext

	useEffect(() => {
		window.scrollTo(0, 0)
		setRecap(null)
		setErrorMobileNumber(null)
		// eslint-disable-next-line
	}, [])

	const refMobileNumber = useRef()
	const refRecap = useRef()
	const refPin = useRef()

	let navigate = useNavigate()

	const submitHandler = async (e) => {
		e.preventDefault();

		// Check phone number
		if (!mobileNumber.match(/(^\d{10}$)/)) {
			setErrorMobileNumber("Please enter a valid phone number");
			setTimeout(() => {
				setErrorMobileNumber(null);
			}, 3000);
			return;
		}

		try {
			const proceed = await getToken(mobileNumber, pin);
			console.log('dis the value ')
			console.log(proceed)

			if (proceed.success === true) {
				localStorage.removeItem("token");
				localStorage.setItem("token", proceed.token);
				setToken(proceed.token);
				setCurrentStep("accountReady");
				setLoginError(null);
			} else if (proceed.success === false) {
				setCurrentStep("login");
				console.log('proceed')
				console.log(proceed)
				if (proceed.status === 403 && proceed?.message?.detail?.long_message) {
					setLoginError(
						proceed.message.detail.long_message
					)
				}
				else {
					setLoginError(
						"Your credentials were either incorrect or do not exist. Either try to register an account or reset your pin"
					)
				}
				// setTimeout(() => {
				// 	setLoginError(null);
				// }, 15000);
			} else {
				setCurrentStep("error");
			}
		} catch (error) {
			console.error(error);
			setCurrentStep("networkError");
		}
	};

	const getToken = async (mobileNumber, pin) => {
		const cellNumArr = mobileNumber.split("").slice(1);
		const cellNumArrFormatted = ["+", "2", "7"].concat(cellNumArr);
		const cell = cellNumArrFormatted.join("");

		try {
			const res = await fetch(`${apiProxy}/gettoken`, {
				method: "POST",
				headers: {
					"Content-type": "application/json",
				},
				body: JSON.stringify({ cell, pin }),
			});

			if (res.status === 403) {
				const errorData = await res.json();
				console.error("403 Error:", errorData);
				return errorData; // Return the error data in case of a 403 response
			}

			const data = await res.json();
			return data;
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const enableBtn = () => {
		if (mobileNumber && pin && recap) {
			return false
		} else {
			return true
		}
	}

	// const enableBtn = () => {
	// 	if (mobileNumber && pin ) {
	// 		return false
	// 	} else {
	// 		return true
	// 	}
	// }

	return (
		<div className="register-widget-container">
			<h2 style={{lineHeight: '40px'}}>{heading || "LOGIN"} </h2>
			<form onSubmit={submitHandler}>
				<div className="form-group" style={{marginTop : '10px'}}>
					<p className="text-red-light error"> {loginError} </p>
				</div>
				<div className="form-group">
					<p className="text-red-light error"> {errorMobileNumber} </p>
					<input
						id="mobileNumber"
						type="text"
						ref={refMobileNumber}
						onChange={() => setMobileNumber(refMobileNumber.current.value)}
						value={mobileNumber || ""}
						placeholder="Enter mobile number here"
					/>
					<label htmlFor="mobileNumber">
						Your mobile number<span className="text-red-light">*</span>
					</label>
				</div>
				<div className="form-group">
					<p className="text-red-light error"> {errorPin} </p>
					<input
						id="pin"
						type="password"
						ref={refPin}
						onChange={() => setPin(refPin.current.value)}
						value={pin || ""}
						placeholder="Type PIN here"
						maxLength={5}
					/>

					<label htmlFor="pin">
						Enter your 5-digit pin<span className="text-red-light">*</span>
					</label>
				</div>
				<ReCAPTCHA
					sitekey="6LeMFMEdAAAAAHA_SpAMwjd1vC-ZGi_vovZNu2MX"
					onChange={(value) => setRecap(value)}
					ref={refRecap}
				/>
				<div className="btn-container">
					<button
						className={`btn btn-register ${enableBtn() === true && "disabled"}`}
						type="submit"
						disabled={enableBtn()}
					>
						Login
					</button>
				</div>
				<button className="form-switch" type="button" onClick={() => navigate("/register")}>
					Don't have an account? <span className="underline"> Register here</span>
				</button>
			</form>
		</div>
	)
}

export default LoginToken