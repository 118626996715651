// Dependencies
import { useContext, useEffect, Fragment, Suspense, lazy } from "react"
import MetaTags from "react-meta-tags"
// import { useLocation } from "react-router-dom"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const AboutShowcase = lazy(() => import("../Modules/AboutShowcase"))
const MMABasics = lazy(() => import("../Modules/MMABasics"))
const WhoMMA = lazy(() => import("../Modules/WhoMMA"))
const LargestRetailer = lazy(() => import("../Modules/LargestRetailer"))
const Footer = lazy(() => import("../Modules/Footer"))

const About = () => {
	// Context
	const appContext = useContext(AppContext)
	const { getAbout, about } = appContext

	// const { search } = useLocation()

	// Scroller to top on page load, fetch content
	useEffect(() => {
		window.scrollTo(0, 0)
		getAbout()
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<MetaTags>
				<title>Money Market Account | About Us | Money Market</title>
				<meta
					name="description"
					content="With Shoprite’s Money Market Account there are no fees and no forms which means no fuss, making it a simple and smart way to save money. Find out more about us, here."
				/>
				<style type="text/css" data-rh="true">
					{`
	:root {
		--primary-color: #59c0a1;
		--secondary-color: #212b36;
		--third-color: #ea222f;
		--primary-font-color: #ffffff;
		--secodary-font-color: #212b36;
		--primary_font_family: 'Poppins', sans-serif;;
		--secodary_font_family: 'Fredoka One', sans-serif;;
		--screen-padding: 60%;
		--component-margin: 40px auto;
		--component-padding: 20px 20px`}
				</style>
			</MetaTags>
			<Suspense fallback={<Spinner />}>
				{about ? (
					<Theme id="about" className="about">
						<AppNavbar />
						<AboutShowcase />
						<MMABasics />
						<WhoMMA />
						<LargestRetailer />
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default About
