// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";

// Global State Wrapper
import AppState from "./Context/App/AppState";
import RegisterState from "./Context/Register/RegisterState";

// Pages
import About from "./Components/Pages/About";
import ForBusiness from "./Components/Pages/ForBusiness";
import ForPeople from "./Components/Pages/ForPeople";
import HowToUse from "./Components/Pages/HowToUse";
import WhereToUse from "./Components/Pages/WhereToUse";
import LinkCard from "./Components/Pages/LinkCard";
import Help from "./Components/Pages/Help";
import Fees from "./Components/Pages/Fees";
import Register from "./Components/Pages/Register";
import Login from "./Components/Pages/Login";
import Security from "./Components/Pages/Security";
import Banking from "./Components/Pages/Banking";
import NotFound from "./Components/Pages/NotFound";
import Promo from "./Components/Pages/Promo";
import RegisterEnd from "./Components/Pages/RegisterEnd";
import ShareDisabled from "./Components/Pages/ShareDisabled";
import SwitchToUs from "./Components/Pages/SwitchToUs";
import Whatsapp from "./Components/Pages/Whatsapp";
import SiteDisabled from "./Components/Pages/SiteDisabled";
import XtraSavingsDeals from "./Components/Pages/XtraSavingsDeals";
import Deals from "./Components/Pages/Deals";
import DealsCustom from "./Components/Pages/DealsCustom";
import Cashback from "./Components/Pages/Cashback";

// Redirect components
const RegisterRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.location.href = "https://register.moneymarketaccount.co.za/register";
  }, [navigate]);
  return null;
};

const LoginRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.location.href = "https://register.moneymarketaccount.co.za/login";
  }, [navigate]);
  return null;
};

// ===============================================================
// Main App
const App = () => {
  return (
    <AppState>
      <RegisterState>
        <Router>
          <Routes>
            <Route exact path="/" element={<ForPeople />} />
            <Route exact path="/for-business" element={<ForBusiness />} />
            <Route exact path="/switch-to-us" element={<SwitchToUs />} />
            <Route exact path="/for-people" element={<ForPeople />} />
            <Route exact path="/for-people/whatsapp" element={<Whatsapp />} />
            <Route exact path="/for-people/how-to-use-cash-wallet" element={<HowToUse />} />
            <Route exact path="/for-people/where-to-use" element={<WhereToUse />} />
            <Route exact path="/for-people/xtra-savings-linking" element={<LinkCard />} />
            <Route exact path="/xtra-savings-linking" element={<LinkCard />} />
            <Route exact path="/help" element={<Help />} />
            <Route exact path="/account-fees-and-limits" element={<Fees />} />
            <Route exact path="/register" element={<RegisterRedirect />} />
            <Route exact path="/offer" element={<RegisterRedirect isAgent={false} />} />
            <Route exact path="/login" element={<LoginRedirect />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/about/account-security" element={<Security />} />
            <Route exact path="/about/banking" element={<Banking />} />
            <Route exact path="/promo" element={<Promo />} />
            <Route exact path="/share" element={<ShareDisabled />} />
            <Route exact path="/maintenance" element={<SiteDisabled />} />
            <Route exact path="/deals" element={<XtraSavingsDeals />} />
            <Route exact path="/unclaimed-benefits" element={<DealsCustom />} />
            <Route exact path="/unclaimed" element={<DealsCustom />} />
            <Route exact path="/unclaimed_CCOD" element={<Deals />} />
            <Route exact path="/cashback" element={<Cashback />} />
            <Route exact path="/register-end" element={<RegisterEnd />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </RegisterState>
    </AppState>
  );
};

export default App;
