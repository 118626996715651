// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, useContext, Fragment, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const XtraSavingsDealsShowcase = lazy(() => import("../Modules/XtraSavingsDealsShowcase"))
const XtraSavingsDealsInfo = lazy(() => import("../Modules/XtraSavingsDealsInfo"))
const XtraSavingsDealsInfoDisabled = lazy(() => import("../Modules/XtraSavingsDealsInfoDisabled"))

const XtraSavingsDealsBanking = lazy(() => import("../Modules/XtraSavingsDealsBanking"))
const XtraSavingsDealsShare = lazy(() => import("../Modules/XtraSavingsDealsShare"))
const Footer = lazy(() => import("../Modules/Footer"))
const XtraSavingsFAQ = lazy(() => import("../Modules/XtraSavingsFAQ"))


// ===============================================================
const XtraSavingsDeals = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { getPeople, people,getFaqs, getAllFaqs } = appContext
	// ---------------------------------------------------------------
	// Get content and scroll to top on load
	useEffect(() => {
		getPeople()
		window.scrollTo(0, 0);
		getFaqs()
		getAllFaqs()
		// eslint-disable-next-line
	}, [])



	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{people ? (
					<Theme id="link-card" className="link-card">
						<AppNavbar />
						<XtraSavingsDealsShowcase />
						<XtraSavingsDealsInfo />
						<XtraSavingsDealsBanking />
						<XtraSavingsDealsShare/>
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default XtraSavingsDeals
