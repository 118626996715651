// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import React, { useContext, Fragment } from 'react'
import Select from 'react-select'

// Context
import AppContext from '../../Context/App/appContext'

// ===============================================================
const WhySwitch = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { image_url, business } = appContext

	// Destructured Variables
	const { howMmaHeading, howMmaParagraph, howMmaIcons, howMmaSelectOptions } = business

	// ---------------------------------------------------------------
	// Options for select dropdown
	const options = []
	howMmaSelectOptions.forEach((option) => {
		options.push(option.flatData)
	})

	return (
		<Fragment>
			{business && (
				<div className='how-mma-business' id='how-mma-business'>
					<div className='bg-top'></div>
					<h2 className='whySwitchHeader' style={{marginTop : '-60px'}}> Why should I switch? </h2>
					<div className='switchCardMainContainer'>
						<div className='switchCardContainer'>
							<div className='cardHeading'>Bank with the<br/> brand you trust</div>
							<div className='cardIcon'><img src='https://cms.computicket.com/api/assets/mma/4c24c795-1762-40fc-a1bb-8de3a0a9c3ad/ci1.png?version=0'></img></div>
							<div className='cardText'>Bank on the power of Shoprite to bring you lowest prices always.</div>

						</div>

						<div className='switchCardMainContainer'>
							<div className='switchCardContainer'>
								<div className='cardHeading'>Save<br/>on fees</div>
								<div className='cardIcon'><img src='https://cms.computicket.com/api/assets/mma/8320e58a-b3c0-4524-a3f8-239232e38e64/ic2.png?version=0'></img></div>
								<div className='cardText'>There are no monthly<br/> fees and most<br/> transactions are FREE.</div>

							</div>
						</div>
						<div className='switchCardMainContainer'>
							<div className='switchCardContainer'>
								<div className='cardHeading'>No debit orders</div>
								<div className='cardIcon'><img src='https://cms.computicket.com/api/assets/mma/81e97eb5-3cf6-4d9c-bf32-39606fe6f824/ic3.png?version=0'></img></div>
								<div className='cardText'>No debit orders or<br/> deductions.</div>

							</div>
						</div>
						<div className='switchCardMainContainer'>
							<div className='switchCardContainer'>
								<div className='cardHeading'>25 000 tills<br/> nationwide</div>
								<div className='cardIcon'><img src='https://cms.computicket.com/api/assets/mma/6f66a83a-dc80-4a3d-a030-3bb82c5c7f08/ic4.png?version=0'></img></div>
								<div className='cardText'>Deposit and withdraw cash<br/> at over 25 000 tills<br/> nationwide.</div>

							</div>
						</div>
						<div className='switchCardMainContainer'>
							<div className='switchCardContainer'>
								<div className='cardHeading'>SAFE <br/>&amp; secure</div>
								<div className='cardIcon'><img src='https://cms.computicket.com/api/assets/mma/6d1bb2fb-83d8-4c74-86c6-51b8421d4319/ci5.png?version=0'></img></div>
								<div className='cardText'>Your account is<br/> PIN-protected only you<br/> can access your money. </div>

							</div>
						</div>
						<div className='switchCardMainContainer'>
							<div className='switchCardContainer'>
								<div className='cardHeading'>Do more noma nini</div>
								<div className='cardIcon'><img src='https://cms.computicket.com/api/assets/mma/d0288b6e-6c59-4264-9e33-135a8a4fc3f7/ic6.png?version=0'></img></div>
								<div className='cardText'>Pay your bills, buy airtime, data, electricity and send money from your phone. No transaction fees. </div>

							</div>
						</div>
					</div>
				</div>

			)
			}

		</Fragment >
	)
}

export default WhySwitch
