// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useContext, Fragment } from 'react'

// Modules
import Button from './Button'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
// Context
import AppContext from '../../Context/App/appContext'
import { Link } from 'react-router-dom'

// ===============================================================
const WhatsappShowCase = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { image_url, business, windowDimensions } = appContext

	// ---------------------------------------------------------------
	// Destructured Variables
	const { showcaseText, showcaseButtons, leftTextFirstRow, leftTextSecondRow, leftTextThirdRow } =
		business.businessShowcase[0].flatData
	const { id: rightImageId } = business.businessShowcase[0].flatData.showcaseRightImage[0]
	const { colorHex: bgColor } = business.businessShowcase[0].flatData.showcaseColor[0].flatData

	return (
		<Fragment>
			{business && (
				<Carousel autoPlay infiniteLoop showStatus={false} showIndicators={false} showThumbs={false} swipeable={false}>

					<div className='people-showcase' >
						<Link to="/switch-to-us#switchHeader">
							<div>
								<picture >
									<source media="(min-width: 992px)" srcSet="https://cms.computicket.com/api/assets/mma/75586a6b-a49a-43cf-b56b-c41eaf129856/w1.png?version=0&quality=90" />
									<source media="(min-width: 501px)" srcSet="https://cms.computicket.com/api/assets/mma/47dcb401-07f8-42b9-9bcf-b1c3b8323d3b/w2.png?version=0?version=0&quality=90" />
									<img src="https://cms.computicket.com/api/assets/mma/3825522d-b5f5-474f-9511-5f4a4c4c902e/w3.png?version=0&quality=90" alt="IfItDoesntMatchAnyMedia" />
								</picture>
							</div>
						</Link>

					</div>

				</Carousel>
			)
			}
		</Fragment >
	)
}

export default WhatsappShowCase
